<template>
    <div class="chileTable">
        <TotalTable :groupName="groupName" :params="params" :isRoot="isRoot" />
    </div>
</template>
  
<script>
//import TotalTable from './TotalTable.vue'; // Replace with the correct path to your MyTable component

export default {
    name: "childVetable",
    props: {
        groupName: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
        isRoot:{
            type:Boolean,
            default:false,
        }
    },
    computed: {
    },
    components: {
        TotalTable:()=>import ('./TotalTable.vue')
    },
};
</script>

<style lang="less" scoped>
.chileTable > thead {
    display: none;
}